import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { LocalStoreService } from '@root/services/local-store.service';
import { PermissionsService } from '@root/services/permissions.service';
import { GeneralStore } from '@root/store/general.store';
import { LocationsStore } from '@root/store/location.store';
import { Observable } from 'rxjs';

export const PermissionsGuard: CanActivateFn = (route, _state) => {
  const generalStore = inject(GeneralStore);
  const locationsStore = inject(LocationsStore);
  const permissionsService = inject(PermissionsService);
  const authService = inject(LocalStoreService);
  const router = inject(Router);
  return new Observable<boolean>(subscriber => {
    const atLeastOneGlobal: number[] = route.data['atLeastOneGlobal'];
    const locationId = Number(route.params['locationId']);
    const atLeastOneLocal: number[] = route.data['atLeastOneLocal'];
    const checkTaskboardPermissions: boolean = route.data['checkTaskboardPermissions'];

    if (_state.url === '/') {
      const token: string = authService.getItem('JWT_TOKEN');
      const refreshToken: string = authService.getItem('JWT_REFRESH_TOKEN');
      router.navigate(['/login'], {
        queryParams: { access_token: token, refresh_token: refreshToken },
      });
    } else if (generalStore.isLoaded()) {
      subscriber.next(
        permissionsService.checkRoutePermissions(
          atLeastOneGlobal,
          locationId,
          atLeastOneLocal,
          checkTaskboardPermissions,
        ),
      );
    } else {
      generalStore.getCurrentUser();
      locationsStore.getEntityData();
      generalStore.loadedNotifier().subscribe(() => {
        subscriber.next(
          permissionsService.checkRoutePermissions(
            atLeastOneGlobal,
            locationId,
            atLeastOneLocal,
            checkTaskboardPermissions,
          ),
        );
      });
    }
  });
};
